/******************popup css**********************/
/******************popup css**********************/
/* .echron-popup .form-main{
    display: block;
} */
.form-main {
    width: 100%;
    float: left;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.575);
    z-index: 5;
}
.wrapper {
    width: 420px;
    padding: 2rem;
    background: #fff;
    box-shadow: 0 0px 2.2px rgba(0, 0, 0, 0.028), 0 0px 5.3px rgba(0, 0, 0, 0.04), 0 0px 10px rgba(0, 0, 0, 0.05), 0 0px 17.9px rgba(0, 0, 0, 0.06), 0 0px 33.4px rgba(0, 0, 0, 0.072), 0 0px 80px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}
.form-main .close {
    cursor: pointer;
    border: 1px solid red;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    text-align: center;
    line-height: 23px;
    text-shadow: none;
    opacity: 1;
    color: #000;
    font-size: 15px;
    position: absolute;
    content: "";
    right: 5px;
    top: 5px;
    background: red;
}
.form-main h2 {
    letter-spacing: 0.1rem;
    padding: 0 0 20px;
    cursor: default;
    margin-top: 0;
    color: #005786;
    font-size: 24px;
    position: relative;
}
.form-main h2::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 8px;
    background: #c1be22;
    width: 50px;
    height: 2px;
}
.form-group {
    margin-bottom: 0px;
    margin-top: 10px;
    width: 100%;
    float: left;
}
.form-main .form-group input[type="text"], .form-main .form-group input[type="email"] {
    height: 43px;
}
.form-main .form-group input[type="text"], .form-main .form-group input[type="email"], .form-main .form-group textarea, .form-main .form-group select {
    width: 100%;
    padding: 1rem 0.8rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: 0;
    transition: border 0.15s;
}

.form-main form, .form-main input, .form-main textarea, .form-main select, .form-main button {
    font-family: inherit;
    font-size: initial;
}

button, input, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font: inherit;
    color: inherit;
}
.form-main .form-group label {
    display: block;
    margin: 2rem 0 0.5rem 0;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
}
.form-main .form-group textarea {
    resize: vertical;
}

.form-main .form-group input[type="text"], .form-main .form-group input[type="email"], .form-main .form-group textarea, .form-main .form-group select {
    width: 100%;
    padding: 1rem 0.8rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: 0;
    transition: border 0.15s;
}
.form-main .submit {
    font-weight: bold;
    margin-top: 1rem;
    border: none;
    background: #0073b1;
    cursor: pointer;
    transition: background 0.15s;
    color: #fff;
    margin: auto;
    display: table;
    width: 120px;
    border-radius: 4px;
    padding: 6px 8px;
}
.fixedButton {
    position: fixed;
    bottom: 0;
    right: -16px;
    padding: 20px;
}
.roundedFixedBtn {
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}
.roundedFixedBtn button.echronEvNeedPop {
    background-color: #ffcc29;
    border: none;
    width: 165px;
    font-size: 16px;
    border-radius: 15px;
    font-family: cursive;
    padding: 10px 0px;
}
.new-index .fixedButton {
    position: fixed;
    bottom: 5px;
    right: 0px;
    padding: 20px;
}
.new-index .roundedFixedBtn {
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}
.new-index .roundedFixedBtn button.echronEvNeedPop {
    background-color: rgb(10, 124, 255);
    border: none;
    width: 95px;
    font-size: 16px;
    border-radius: 30px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    padding: 10px 0px;
    color: #fff;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap: 3px;
}








/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 1200px) and (max-width: 1599px) {

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 1600px){

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 992px) and (max-width: 1199px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 768px) and (max-width: 991px) {
   
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 767px) {
    .wrapper {
        width: 331px;
        top: 48%;
    }
    .form-main .close {
        width: 28px;
        height: 27px;
    }
    
}