/* .Echron-Years{
    padding: 50px 0;
}
.Echron-Years .card{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
}
.Echron-Years .card figure{
    width: 130px;
    height: 130px;
}
.Echron-Years .card figure img{
    width: 100%;
}
.Echron-Years .card p{
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
} */
.echron-counters {
    padding: 35px 0;
    background: url(https://www.property4sure.com/img/listings-parallax.jpg);
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
   
}
.overview-bgi:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
}
.echron-counters .main-title h1{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
    color: #fff;
}
.echron-counters .container{
    position: relative;
}
.echron-counter-box {
    margin-bottom: 5px;
    text-align: center;
    border: 1px dotted white;
}
.echron-counter-box h6{
    color: #fff;
}
.echron-counter-box a{
    text-decoration: none;
}
.echron-counter-box i {
    font-size: 45px;
    color: #fff;
    line-height: inherit;
}





/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 1200px) and (max-width: 1599px) {

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 1600px){

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 992px) and (max-width: 1199px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 768px) and (max-width: 991px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 767px) {
    
    .echron-counters {
      margin-bottom: 0;
   }
   .echron-counters .container .row{
    display: unset;
   }
   .echron-counters .container .row .col{
    margin-bottom: 25px;
   }
}