.Echron-Slides{
    padding: 40px 0;
    width: 100%;
    background-color: #fff;
}
.Echron-Slides h2{
    text-align: center;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0;
}
.Echron-Slides p{
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    color: #999;
   
}
.Echron-Slides .SliderText{
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-align: center;
    margin-top: 20px;
}
.Echron-Slides .SliderText p{
    text-align: center;
    text-transform: capitalize;
    font-weight: 400;
    color: #999;
    font-size: 13px;
}
.Echron-Slides .SliderText .EchronText{
    margin-right: 10px;
}
.Echron-Slides .SliderText img{
    height: 50px;
    transition: 0.5s ease-in-out;
    margin: auto;
}
.Echron-Slides .SliderText .EchronButton{
        border: none;
        color: #19a767;
        background-color: #fff;
        font-size: 12px;
}
.Echron-Slides .SliderText img:hover{
    scale: 1.1;
}







/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 1200px) and (max-width: 1599px) {

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 1600px){

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 992px) and (max-width: 1199px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 768px) and (max-width: 991px) {
   
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 767px) {
    
    .Echron-Slides .slick-slider button.slick-arrow {
        display: none !important;
    }
}