/* .Echron-about{
    padding: 50px 200px;
}
.Echron-about figure img{
    width: 11%;
}
.Echron-about h2{
    font-size: 28px;
    width: 100%;
    margin-bottom: 25px;
    text-transform: uppercase;
    font-weight: bold;
}
.Echron-about p {
    font-size: 18px;
    line-height: 32px;
    color: #23242c;
} */

.about-banner{
    width: 100%;
    height: 100%;
    position: relative;
}

.about-banner img{
    width: 100%;
    height: 100%;
    filter: brightness(60%);
}

.about-banner h2{
    position: absolute;
    bottom: 5%;
    left: 10%;
    color: #fff;
    text-transform: capitalize;
}

.about-container{
    padding: 40px;
    width: 100%;
    /* background-color: #fff; */
}


.about-container h2 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 1rem;
}

.about-container p{
    font-size: 0.9rem;
    margin: 0;
    text-align: justify;
}

.about-upper{
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.upper-left{
    width: 40%;
}

.upper-right{
width: 55%;
}

.about-lower{
    margin-top: 2rem;
}