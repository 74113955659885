.echron-cards{
  /* background-color: #eeeeee; */
  background-color: #ebebeb;
  position: relative;
  padding: 25px 0;
  margin-top: 2rem;
}

.card{
  text-decoration: none;
}

.echron-cards .slick-next {
  right: -60px;
}
.echron-cards .slick-prev {
  left: -60px;
}



.Echron-Samplework .echron-cards {
  background: none;
}
.Echron-Samplework .echron-cards .carousel-item {
  
  height: 180px !important;
}
.echron-cards h2{
  font-size: 24px;
}
.echron-cards p{
  color: #555;
  font-weight: 600;
}
.echron-cards .card{
  background-color: #fff;
  height: 100%;
  position: relative;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  padding-bottom: 1rem;
  border-radius: 15px ;
}




.echron-cards .card:hover{
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  scale: 1.03;
  background-color: #fdfdfd;
}

.echron-cards .card:hover figure img{
  scale: 1.5;
  transition: 1s;
}

.echron-cards .text{
  text-align: center;
}
.topslider  figure {
  width: 100%;
  height: 270px !important;
  overflow: hidden;
  border-radius: 15px ;
  overflow: hidden;
  
}
.topslider figure .carousel-control-prev,
.topslider figure .carousel-control-next {
  height: 270px !important;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 1rem !important;
  height: 1rem !important;
  /* border: 1px solid #fff; */
  background-color: #000000;
  padding: 0.7rem 0.5rem;

}

.topslider .text-head{
  display: flex;
  justify-content: space-between;
  align-items: baseline;


}

/* .topslider .text-head-left{
  width: 20%;
  height: 50px;

} */

.topslider .text-head-left img{
  width: 100%;
  height: 100%;
  object-fit: contain;


}

.topslider .text-head-right{
  width: 100%;
}

.topslider .heading{
  display: flex;
  justify-content: space-between;
}


.topslider figure img {
  width: 100%;
  height: 270px !important;
  border-radius: 15px;
  transition: 1s;
}
.echron-cards .echron_con_rgt  img{
  margin: 0px 10px;
  padding: 4px 0 19px 20px;
}
.echron-cards .icon:hover {
  color: red;
}
.echron-cards .icon{
  content: "";
  position: absolute;
  top: 100px;
  right: 20px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  transition: all .5s;
  z-index: 9;
}
.echron-cards .bed {
  content: "";
  position: absolute;
  top: 7px;
  font-size: 0.8rem;
  right: 10px;
  z-index: 9;
  background-color: #ac28a3;
  color: #fff;
  padding: 0 5px;
  border-radius: 0 0px 0px 0px;
  text-transform: capitalize;
  border-radius: 5px;
}
.echron-cards .bed.left {
  right: auto;
  left: 10px;
  background-color: #00203fd9;
  border-radius: 5px;
}
.echron-cards .content {
  padding: 0 10px 10px 10px;
}
.echron-cards .content .name {
  display: flex;
  flex-direction: column;
}
.echron-cards .content .name .h3 {
  font-size: 20px;
  color: #000000;
  margin-bottom: 10px;
}

.short-description p{
  line-height: 2;
  height: 50px; /* Set this to your desired height */
  overflow: hidden;
  text-align: justify;
  font-weight: 400;
  font-size: 0.8rem;
}

.topslider .short-description{
  display: block;
}


.short-description{
  display: none;
}

.echron-cards .content .name a{
  text-decoration: none;
  color: #000;
}
.echron-cards .content .loc p span{
  text-transform: capitalize;
  color: #686868;
  font-size: 0.8rem;
  font-weight: 400;
}
.echron-cards .content .loc h6{
  text-transform: capitalize;
  color: #555;
  font-weight: 100;
  
}
.echron-cards .content .name .tags {
  display: flex;
  justify-content: left;
  gap: 1rem;
  margin-bottom: 15px;
}

/* .topslider .content .name .tags {
  display: none ;
}

.topslider  .echron-cards .content .name .tag {
  display: none;
} */

.echron-cards .content .name .tag {
  border-radius: 25px;
  font-weight: 700;
  text-transform: uppercase;
  background: #f1f1f1;
  /* height: 25px; */
  padding-left: 10px;
  padding-top: 3px;
  padding: 0 0.5rem;
  padding-bottom: 3px;
  font-size: 14px;
  width: fit-content;
}
/* .echron-cards .content .name .tag:nth-child(2) {
  width: fit-content;
} */
.echron-cards .content .name .tag i {
  content: "";
  color: green;
  font-size: 12px;
  margin-right: 5px;
}
.echron-cards .content .loc .h4{
  margin: 10px 0px;
  font-size: 14px;
}
.echron-cards .content .sqft {
  border-bottom: 2px solid #f1f1f1;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-bottom: 8px;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
}
.echron-cards .content .price{
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
}
.echron-cards .content .price h5{
  font-size: 14px;
  color: #000;
  margin: 0;
  
}
.echron-cards .content .price .button a{
  padding: 5px 20px;
  background: #19a767;
  border-radius: 25px;
  border: none;
  color:#ffffff;
  transition: all .5s;
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 600;
}
.echron-cards .content .price .button a:hover{
  color:#fff;
  background: #19a767;
}

/* carousel carousel carousel */
/* carousel carousel carousel */
/* carousel carousel carousel */
.echron-cards .carousel-control-prev:not(.echron-cards figure .carousel-control-prev), .echron-cards .carousel-control-next:not(.echron-cards figure .carousel-control-next) {
  background-color: #ccc;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 30%;
  border: 2px solid #fff;
  fill: red;
}
.echron-cards .carousel-control-prev:not(.echron-cards figure .carousel-control-prev) {
  left: -25px;
}
.echron-cards .carousel-control-next:not(.echron-cards figure .carousel-control-next) {
  right: -25px;
}


@media (max-width: 567px) {
  .echron-cards .carousel-control-prev:not(.echron-cards figure .carousel-control-prev) {
    display: none !important;
  }
  .echron-cards .carousel-control-next:not(.echron-cards figure .carousel-control-next) {
    display: none !important;
  }
  .echron-cards .content .price .button {
    display: none !important;
  }
  
}