.searchingSection{
    display: flex;
    justify-content: space-between;
    margin: 2rem;
}

.searchingSection  .echron-main{
    width: 100%;

}

.searchingSection .firstBox{
    width: 25%;
    height: fit-content;
    border: 2px solid #ccc;
    padding: 2%;
    background-color: #fff;
}


.scrollable-filter{
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #495679 #dbdbdb;
    height: 70vh;
    padding: 0 1rem;
}

.ulFilterlist{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

/* .activeFilter {
    display: flex;
    align-items: center;
    position: relative;
    font-weight: bold;
    background-color: #aacaab  !important;
    color: #000000;
} */
.ulFilterlist li p{
    height: fit-content;
    margin-top: 1rem;
}
.filterReset{
    position: absolute;
    top: -10px;
    right: -2px;
    color: #fff;
    text-align: center;
    font-size: .8rem;
    background-color: orange;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    border-radius: 50%;
    cursor: pointer;
}

.dactiveFilter{
    display: none !important;
}

.searchingSection .firstBox .filter .filterhead button{
    border: none;
    font-size: .9rem;
    color: rgb(43, 7, 248);
    font-weight: 600;
}
.searchingSection .firstBox .filter{
    margin: 1rem 0;
}
.searchingSection .firstBox .filter .filterhead{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.searchingSection .firstBox .filter .inputBudget{
    width: 100%;
}
#proprtyTypeFilter button{
    border: none;
    border-bottom: 1px solid #ddd;
    background-color: transparent;
    font-size: .8rem;
    border-radius: 1rem;
    background-color: a0a0a028;
}
.searchingSection .firstBox .filter ul{
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    margin: 0;padding: 0;
}
.searchingSection .firstBox .filter ul li{
    list-style: none;
    padding:5px 10px;
    margin: 5px;
    border-radius: 1rem;
    background-color: a0a0a028;
    font-size: .8rem;
}
.searchingSection .firstBox .filter ul li:hover{
    background-color: red !important;
    color: #fff;
    cursor: pointer;
}
.searchingSection .firstBox .filter ul li a{
    text-decoration: none;
    color: rgb(71, 70, 70);
}
.searchingSection .firstBox .filter ul li:hover{
    background-color: #ddd;
}
.activepro , .activestatus{
    background-color: orangered !important;
    color: #fff;
}
.lokflilterbox {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.rendHide{
    display: none;
}
.lokflilterbox .localityfilter{
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: .8rem;
}

.lokflilterbox button{
    border: none;
    background-color: #a9a9a9;
    color: #000000;
    width: fit-content;
    font-size: 0.7rem;
    font-weight: 600;
    border-radius: 5px;
}

.searchingSection .secoundBox{
    width: 73%;
    padding: 2%;
    border: 2px solid #ccc;
    background-color: #fff;
}





.secoundFilter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    color: #00203F;
}
.secoundFilter h5{
    text-transform: capitalize;
}
.secoundFilter ul{
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}
.secoundFilter ul li{
    list-style: none;
    font-size: .8rem;
    padding: 5px 10px;
    border-radius: 1rem;
    background-color: rgb(201, 197, 197);
    margin-right: .5rem;
}
.shortby select{
    border: none;
    background-color: transparent;
    font-size: .9rem;
    color: #00203F;
}

.shortby option{
    color: #00203F;
}

.listingContainer{
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    gap: 1rem;
    border-radius: 1rem;
    text-transform: capitalize;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}
.listingContainer .listingImagesBox{
    width: 40%;
    position: relative;
    /* height: 280px; */
}
.listingContainer .listingImagesBox .listingimg{
    width: 100%;
    height: 100%;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    overflow: hidden;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
.listingContainer .listingImagesBox .listingimg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.listRent{
    position: absolute;
    top: 0;
    padding: 2px 10px;
    background-color: rgb(70 169 0);
    color: #fff;
    border-radius: 10px;
    font-size: .7rem;
    font-weight: 600;
    margin: 10px;
}
.listSale{
    position: absolute;
    top: 0;
    padding: 2px 10px;
    background-color: #ff0404;
    color: #fff;
    border-radius: 10px;
    font-size: .7rem;
    font-weight: 600;
    margin: 10px;
}
.listingContainer .listingContent{
    width: 59%;
    padding: 0.5rem;
    /* display: flex;
    flex-direction: column;
    justify-content: space-around; */

}
.ListContenthead{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.1rem;
}
.listingContainer .ListContenthead h5{
    width: 70%;
}
.listingContainer .ListContenthead .listingCtr{
    width: fit-content;
    font-size: .8rem;
    text-align: right;
    font-weight: 700;
    background-color: #a0a0a028;
    color: #29192b;
    border-radius: .5rem;
    padding: .2rem;
}
.listingContainer .ListContenthead .listingCtr p{
    margin: 0px 5px;
}
.listingContainer .listheadLok{
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;
}
.listingContainer .listheadLok span{
    font-size: .8rem;
    margin-left: .5rem;
}
#listingSmD{
    font-size: .8rem;
    color: rgb(71, 70, 70);
    text-transform: none;
}
.listingFeature{
    display: flex;
    justify-content: left;
    gap: 1.5rem;
    text-align: center;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.listingFeature i{
    color: #19a767;
    font-size: 1.3rem;
}

.listingFeature i::nth-child(3) {
    color: orange;
}

.listingFeature p{
    font-size: .7rem;
    margin: 0;
}

.listingBtns{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.listingBtns p{
    font-weight: 600;
    font-size: 1.2rem;
    color: #00203F;
    margin: 0;
}

.listingBtns a{
    text-decoration: none;
    padding:5px 10px;
    color:#19a767;
    margin-left: .5rem;
    background-color: #a0a0a028;
    font-weight: 700;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    /* border-bottom: 1px solid #19a767; */
    font-size: .8rem;
}

.notfounddata a{
    display: block;
    padding: .5rem;
    border: 2px solid #ddd;
    text-align: center;
    width: 180px;
    margin: auto;
    text-decoration: none;
    color: #000;
    font-size: .9rem;
    font-weight: 500;
    background-color: #eee;
}




.slider-container {
    width: 90%;
    max-width: 600px;
    margin: 40px auto 2px auto;
    
  }
  
  .slider-wrapper {
    position: relative;
    width: 100%;
    margin-top: 2rem;
  }

  .slider-wrapper input[type='range'] {
    background-color: #19a767;
    color: #19a767;
  
    height: 5px;
  }

 

  .slider-track {
    position: absolute;
    top: 5.5px;
    height: 6px;
    background: transparent;
    color: #fff;
    border-radius: 5px;
    width: 100%;
    z-index: 0;
  }
  
  .slider {
    position: absolute;
    width: 100%;
    height: auto;
    background: #19a767;
    color: #fff;
    outline: none;
    border: none;
  }
  
  .slider::-webkit-slider-thumb {
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    z-index: 1;
  }
  
  
  .slider-values {
    position: absolute;
    width: 100%;
    top: -20px;
    display: flex;
    justify-content: space-between;
    pointer-events: none; 
  }
  
  .slider-values span {
    font-size: 10px;
    background: white;
    border: 1px solid #ddd;
    padding: 2px 6px;
    border-radius: 4px;
    white-space: nowrap;
    transform: translateY(-50%);
    position: absolute;
  }

  .minmaxbudgetBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin: auto;
    margin-top: -1rem;
  }
  .minmaxbudgetBox .minmxbgt{
    width: 48%;
  }

  .minmaxbudgetBox span{
    font-size: 0.8rem;
    font-weight: 600;

  }



  .minmaxbudgetBox .minmxbgt input{
    width: 100%;
    border-radius: 8px;
    border: 0.5px solid #555555;
    padding-left: 5px;
  }