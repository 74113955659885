.echron-properties{
  background-color: #29192b;
  padding:80px 0px;
}
.echron-properties .col{
  display: flex;
  justify-content: space-between;
}
.echron-properties .col ul{
  list-style: none;
}
.echron-properties .col ul li{
  margin: 0 30px 20px 0;
  font-size: 13px;
}
.echron-properties .col ul li:first-child{
  font-size: 14px;
  font-weight: 600;
  color: #f2eded;
}
.echron-properties .col ul li a{
  color: #dcd6d6;
  text-decoration: none;
}
.echron-properties .col ul li a:hover {
  color: #19a767;
  transition: all .5s ease;
}






/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 1200px) and (max-width: 1599px) {

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 1600px){

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 992px) and (max-width: 1199px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 768px) and (max-width: 991px) {
   
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 767px) {
    
    .echron-properties {
      padding: 30px 0px 20px;
      display: none;
  }
  .echron-properties .col {
    display: unset;
  }
  .echron-properties .col .first{
    margin-bottom: 40px;
  }
  .echron-properties .col .second{
    margin-bottom: 40px;
  }
  .echron-properties .col .third{
    margin-bottom: 40px;
  }
  .echron-properties .col .fourth{
    margin-bottom: 40px;
  }
  .echron-properties .col ul li {
    margin: 0 30px 10px 0;
    font-size: 18px;
  }
  .echron-properties .col ul li:first-child {
    font-size: 25px;
  }
}