/****************common*******************/
.echron-detail .container-fluid{
  width: 90% !important;

}



.container{
  width: 90% !important;
}
.echron-common-h1::after {
  content: "";
  width: 55px;
  height: 3px;
  display: block;
  position: absolute;
  background-color: #19a767;
  /* top: 61px; */
  bottom: -10px;
  left: 0;
}
.echron-common-h1::before {
  content: "";
  width: 98%;
  height: 1px;
  display: block;
  position: absolute;
  background-color: #f1f1f1;
  bottom: -10px;
  left: 0;
}
.echron-common-h1 {
  position: relative;
  color: #393838;
  font-size: 23px !important;
  font-weight: 500;
  margin-bottom: 40px;
}
/****************common*******************/
/****************banner*******************/
/****************banner*******************/
/****************banner*******************/
.echron-detail{
  background-color: #ececec;
 
}
.echron-detail .banner {
  height: 80vh;
  position: relative;
}
.echron-detail .banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: none;
}

.banner-text{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50% , -50%);
  background-color: #19a767;
  color: #ffff;
  text-transform:uppercase;
  padding: 0.5rem 1rem;
}

/****************breadcrumbs*******************/
/****************breadcrumbs*******************/
/****************breadcrumbs*******************/
/****************breadcrumbs*******************/
/****************breadcrumbs*******************/
.echron-detail .breadcrumbs {
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  display: flex;
  list-style: none;
  gap: 5px;
  padding: 15px;
  color: #9C9C9C;
  text-transform: capitalize;
}
.echron-detail .breadcrumbs li a {
  color: #9C9C9C;
}

/****************header*******************/
/****************header*******************/
/****************header*******************/
/****************header*******************/
/****************header*******************/
.echron-detail .body {
  margin-top: -5rem ;
}

.echron-detail .body .row .left-container{
  z-index: 2 !important;
}

.echron-detail .body .row .right-container{
  z-index: 2 !important;
}



.echron-detail .left .heading{
  background-color: #fff;
  padding: 15px;
  margin: 0px 0 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  
}
.echron-detail .left .heading h1 {
  font-size: 1.7rem;
  margin-right: 1rem;
  font-weight: 700;
}

.heading-rera{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.echron-detail .left .heading p i.last {
  transform: rotate(30deg);

}
.echron-detail .left .heading p i {
  color: #19a767;
}
.echron-detail .left .heading p
 {
  color: #7c7c7d;
  text-transform: capitalize;
}
.echron-detail .left .heading .d-flex{
  justify-content: space-between;
  align-items: center;
}
.echron-detail .left .heading .tag {
  border-radius: 25px;
  font-weight: 700;
  text-transform: uppercase;
  background: #f1f1f1;
  padding: 5px 10px;
  height: 25px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.echron-detail .left .heading .tag i {
  color:#fff;
  background-color: #19a767; 
  border-radius: 25px;
  font-size: 12px;
  margin: 0px 3px;
}

.echron-detail .left .heading .tag p {
  font-size: 0.7rem;
  margin: 0;
  margin-left: 0.5rem;
}

.echron-detail .left .heading .right p.blk{
  color: #000;
  font-weight: 700;
  font-size: 1.2rem;
}
/****************.EchronHomebanner*******************/
/****************.EchronHomebanner*******************/
.EchronHomebanner .banner-image .icon1, .EchronHomebanner .banner-image .icon2 {
  position: absolute;
  bottom: 3%;
  right: 2%;
  background-color: #fff;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  transition: .5s;
}
.EchronHomebanner .banner-image i {
  padding: 10px;
  border-radius: 50%;
  color: #7c7c7d;
}
.EchronHomebanner .banner-image .icon2 {
  right: auto;
  left: 2%;
}
/****************.EchronTab*******************/
/****************.EchronTab*******************/

.EchronHomebanner .carousel-control-prev-icon, .EchronHomebanner .carousel-control-next-icon {
  width: 2rem !important;
  height: 2rem !important;
  padding: 1rem !important;
  background-color: #000000;
  /* border-radius: 50%; */
}


.EchronTab {
  margin: 5px 0 0px;
  background-color: #fff;
}
.EchronTab ul.nav-tabs {
  border: none;
}
.EchronTab li {
  margin: 0 0 0 20px;
  color: #000000;
  position: relative;
  padding: 10px 0;
  display: block;
  cursor: pointer;
}
.EchronTab li.active {
  border-bottom: 7px solid #19a767;
}
.EchronTab li a.active {
  border-bottom: 7px solid #19a767;
}
.EchronTab li a {
  padding: 10px 0;
  display: block;
  cursor: pointer;
}

.EchronTab li a {
  text-decoration: none;
  color: #999696;
  position: relative;
}
.EchronTab {
  width: 100%;
  background-color: #fff;
  margin: 5px 0px;
  border: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  position: sticky;
  top: 72px;
  z-index: 1;
}
/****************details*******************/
.echron-detail .EchronDetail {
  padding: 20px 15px;
  margin: 5px 0 0px;
  background-color: #fff;
}
.echron-detail .EchronDetail h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 25px;
}
.echron-detail .EchronDetail p {
  text-align: justify;
  font-size: 14px;
  color: #414141;
}
.echron-detail .EchronDetail ul{
  padding: 0px;
  margin-bottom: 12px;
  list-style: none;
}
.EchronDetail_textoverview{
  padding:5px 0 5px 5px;
  margin-bottom: 10px;
  border: 1px solid #c6c7c8;
  border-radius: 10px;
}
.echron-detail .EchronDetail ul li p {
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 700;
  color: #29192b;
}
/* .echron-detail .EchronDetail ul li:last-child{
  border: 1px solid #7c7c7d;
} */
.echron-detail .EchronDetail ul li b{
  color: #414141;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
}
/* .echron-detail .EchronDetail ul li span {
  color: #7c7c7d;
  font-size: 11px;
  font-weight: 600
} */
.echron-detail .EchronDetail .rera{
  background-color: #00203fd9;
  padding: 10px;
  color: #fff;
  font-weight: 600;
}
.echron-detail .EchronDetail .num{
  border: 1px solid #7c7c7d;
  text-align: center;
  padding: 15px 15px 0;
  margin: 0;
  color: #7c7c7d;
}
.echron-detail .EchronDetail .num span{
  color: #000;
  font-weight: 600;
}
.echron-detail .EchronDetail .echron-button {
  width: 17%;
  height: 40px;
  background-color: #19a767;
  color: #fff;
  border-radius: 5px;
  border: none;
  align-items: center;
  text-align: center;
  transition: .5s;
  display: flex;
  justify-content: center;
  margin: 30px auto 0px;
}
/****************EchronPlan*******************/
/****************EchronPlan*******************/
/****************EchronPlan*******************/
/****************EchronPlan*******************/
/****************EchronPlan*******************/
.echron-detail .EchronPlan{
  padding: 20px 15px;
  margin: 5px 0 0px;
  background-color: #fff;
}
.echron-detail .EchronPlan h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 50px;
}
.echron-detail .EchronPlan .top {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  height: 90px;
}
.echron-detail .EchronPlan .top .text {
  text-align: left;
  padding: 10px 50px;
  background-color: #eee5c8;
  border-left: 10px solid #bc9e2c;
}
.echron-detail .EchronPlan .top .logo img{
  width: 200px;
}
.echron-detail .EchronPlan .top h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  color: #414141;
}
.echron-detail .EchronPlan .top p {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 5px;
  font-weight: 500;
  color: #414141;
}
.echron-detail .EchronPlan .bottom {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 60px;
  cursor: pointer;
  position: relative;
}

.echron-detail .EchronPlan .bottom img{
  filter: blur(1px);
}

.echron-detail .EchronPlan .bottom:hover .floorplan-text{
  opacity: 1;
  transition: 1s;
}

.echron-detail .EchronPlan .bottom .floorplan-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  background-color: #eee5c8;
  padding: 1rem 2rem;
  color: #7c7c7d;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: 700;
  opacity: 0;
  transition: 1s;
}


.echron-detail .EchronPlan .cont {
  border-bottom: 1px solid #7c7c7d;
  padding: 10px;
  color: #000;
  font-weight: 700;
}
.echron-detail .EchronPlan .cont p:first-child {
  color: #414141;
  font-weight: bold;
  font-size: 14px;
}

.echron-detail .EchronPlan .cont p {
  margin: 0;
  padding-bottom: 12px;
  font-weight: 500;
  color: #29192b;
}

/****************EchronAmenities*******************/
/****************EchronAmenities*******************/
/****************EchronAmenities*******************/
/****************EchronAmenities*******************/
/****************EchronAmenities*******************/
.echron-detail .EchronAmenities{
  padding: 20px 15px;
  margin: 5px 0 0px;
  background-color: #fff;
}
.echron-detail .EchronAmenities h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 40px;
}
.echron-detail .EchronAmenities ul {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 25px;
  padding: 0;
  margin: 0;
}
.echron-detail .EchronAmenities ul li {
  border-bottom: 1px solid #414141;
  list-style: none;
  color: #414141;
  width: 218px;
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: stretch;
  gap: 10px;
  font-size: 14px;
}
.echron-detail .EchronAmenities ul li i {
  padding: 15px;
  border-radius: 50%;
  color: #993f7f;
  background-color: #fff;
  border: 1px solid #7c7c7d;
}

/****************EchronSpecific*******************/
/****************EchronSpecific*******************/
/****************EchronSpecific*******************/
/****************EchronSpecific*******************/
/****************EchronSpecific*******************/

.echron-detail .EchronSpecific{
  background-color: #fff;
  padding: 20px 15px;
  margin: 5px 0 0px;
}
.echron-detail .EchronSpecific h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 40px;
}
.echron-detail .EchronSpecific ul{
  padding-left: 1rem;
}
.echron-detail .EchronSpecific ul li{
  color: #333333;
  /* font-weight: 700; */
  padding: 5px 0;
  font-size: 14px;
}

/****************EchronPayment*******************/
/****************EchronPayment*******************/
/****************EchronPayment*******************/
/****************EchronPayment*******************/
/****************EchronPayment*******************/
.echron-detail .EchronPayment{
  padding: 20px 15px;
  margin: 5px 0 5px;
  background-color: #fff;
}
.echron-detail .EchronPayment h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 40px;
}
.echron-detail .EchronPayment table{
  width: 100%;
}
.echron-detail .EchronPayment table th{
  background-color: #00203fd9;
  height: 55px;
  padding-left: 42px;
  margin-bottom: 20px;
  color: #fff;
}
.echron-detail .EchronPayment table td {
  padding-left: 42px;
  margin-bottom: 20px;
  color: #7c7c7d;
  line-height: 30px;
  font-weight: 700;
  font-size: 14px;
}
#collapse1{
  overflow-y:scroll;
  height:200px;
}
/****************Echroncards*******************/
/****************Echroncards*******************/

.echron-detail .echron-cards{
  background-color: #fff;
  padding: 10px 0px;
}
.echron-detail .map{
  background-color: #fff;
  padding: 20px 15px;
  margin: 5px 0;
}

/****************EchronProperties*******************/
/****************EchronProperties*******************/
/****************EchronProperties*******************/
/****************EchronProperties*******************/
/****************EchronProperties*******************/
.EchronProperties {
  background-color: #00203fd9;
  padding: 80px 0px;
  overflow: hidden;
}
.EchronProperties .col{
  display: flex;
  justify-content: space-between;
}
.EchronProperties .col ul{
  list-style: none;
}
.EchronProperties .col ul li{
  margin: 0 30px 20px 0;
  font-size: 13px;
}
.EchronProperties .col ul li:first-child{
  font-size: 14px;
  font-weight: 600;
  color: #f2eded;
}
.EchronProperties .col ul li a{
  color: #dcd6d6;
  text-decoration: none;
}
.EchronProperties .col ul li a:hover {
  color: #19a767;
  transition: all .5s ease;
}
/**********************************footer sec start ****************************************/
/**********************************footer sec start ****************************************/
/**********************************footer sec start ****************************************/
/**********************************footer sec start ****************************************/
.echron-detail .EchronRight {
  position: sticky;
  top: 72px;
  height: fit-content;
}
.echron-detail .EchronSide {
  background-color: #fff;
  padding: 25px;
  font-size: 12px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.echron-detail .EchronSide input {
  margin-bottom: 20px;
}

.echron-detail .EchronSide textarea {
  margin-bottom: 20px;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.echron-detail .EchronSide input {
  margin-bottom: 20px;
}
.echron-detail .EchronSide .echron-button {
  width: 100%;
  height: 50px;
  background-color: #19a767;
  color: #fff;
  border-radius: 5px;
  border: none;
  align-items: center;
  text-align: center;
  transition: .5s;
  display: flex;
  justify-content: center;
  cursor: pointer;
}



/* question  */

.question{
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 5;
  margin-bottom: 1rem;
  background-color: #fff;
  
}
.question h3{
  color: #242424;
  font-weight: bold;
  font-size: 20px;
  padding-bottom:5px;
  margin-bottom: 10px;
  border-bottom:2px solid #0a1d3b;

}
.question p{
  margin-bottom: 10px;
  font-size: small;
}

  .question p:last-child{
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: small;
}
.question a{
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}



/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 1200px) and (max-width: 1599px) {

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 1600px){

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 992px) and (max-width: 1199px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 768px) and (max-width: 991px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 767px) {
   
    
    .echron-common-h1::before {
      bottom: -10px;
      left: 0; 
    }
    .echron-common-h1::after {
      width: 70px;
      bottom: -10px;
      left: 0;
  }
    .echron-detail .EchronDetail .echron-button {
      width: 40%;
    }
    .echron-detail .EchronPayment h3 {
      margin-bottom: 65px;
    }
    .echron-detail .container-fluid{
      width: 90% !important;
    }
    
    footer .feet .container{
      width: 90% !important;
    }
    .EchronProperties {
      display: none;
    }
    .EchronTab ul.nav-tabs {
      margin: 0 20px;
    }
    .echron-detail .breadcrumbs {
      display: none;
    }
    .echron-detail .EchronPlan .top .text {
      text-align: left;
      padding: 10px 5px;
      background-color: #eee5c8;
      border-left: 5px solid #bc9e2c;
      height: fit-content;
      width: fit-content;
    }
}