.EchronNav{
  position: relative;
}
.EchronNav .open-handel {
position: absolute;
top: 272px;
rotate: 90deg;
background-color: #000000;
padding: 0.3rem 0.8rem;
color: #fff;
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
cursor: pointer;
text-decoration: none;
font-weight: 600;
right: 270px;

}
.EchronNav .echron-show {
  background-color: #29192b;
  height: 100vh;
  width: 300px;
  padding: 10px 0 0 20px;
  position: fixed;
  top: 0;
  right: -300px;
  transition: all .5s;
}
.EchronNav .echron-show.animate {
  right: 0;
}
.EchronNav .echron-show .echron-text{
  border-bottom: 1px solid  #1F6175;;
  padding-bottom: 20px;
}
.EchronNav .echron-show .echron-text i{
  font-size: 40px;
  color: #fff;
  margin-bottom: 20px;
  cursor: pointer;
  
}
.EchronNav .echron-show .echron-text ul{
  padding: 0;
  margin: 0;
  display: unset;
}
.EchronNav .echron-show .echron-text ul li a{
  text-align: left;
  border-left: 2px solid #19a767;
  margin-bottom: 20px;
  color: #fff;
  list-style: none;
  margin: 0.5rem 0;
  padding: 0.5rem;
  font-size: 16px;
}
.EchronNav .echron-show .echron-text ul li a:hover{
  background: linear-gradient(to left, #19a767 50%, #29192b 49%) right;
  background-size: 300% 100%; 
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transform-origin: 100% 50%;
  -webkit-transition: 300ms ease-out;
  transition: 300ms ease-out;
}
.contact-container{
  padding: 1rem;
  
}

.contact-container h4{
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
}
.contact-container .contact-text{
  margin-top: 1rem;
  background-color: #19a767;
  padding: 1rem;
}

.contact-container .contact-text a{
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0.5rem 0;
 
}
.contact-container .contact-text a i{
  font-size:24px;
}

.contact-container .contact-text a:hover{
  color: #FDE882;

}

.contact-container .contact-text span{
  margin-right: 1rem;
}
