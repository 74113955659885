.slick-next, .slick-prev {
  z-index: 1;
  height: 50px;
  width: 50px;
}
.slick-prev::before {
  color: #118551;
  font-size: 37px;
  margin-right: 12px;
}
.slick-next::before{
  color: #118551;
  font-size: 37px;
  margin-left: 10px;
}

.slick-next {
  right: -60px;
}

.slick-prev {
  left: -60px;
}


.slick-prev:before {
  content: '〈';
}

.slick-next:before {
  content: '〉';
}

