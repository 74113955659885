.searchingSection{
    display: flex;
    justify-content: space-between;
    margin: 2rem;
}
.activeFilter{
    background-color: #19a767;
    color: #fff;
    font-weight: 400;
}
.searchingSection label {
    margin: none;
}

.searchingSection .appliedFilter{
    padding: 0.7rem;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;  
}

.searchingSection .appliedFilter h5{
    font-size: 1.2rem;
    color: #29192B;
}

.searchingSection .appliedFilter a{
    color: #19a767;
    font-weight: 600;
    font-size: 1rem;
}

.searchingSection .appliedFilter .filter-applied-box{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.searchingSection .appliedFilter .filter-applied-box ul{
    display: flex;
    gap: 8px;
    width: fit-content;
    flex-wrap: wrap;
    padding: 0;
}
.ullistboard{
    text-decoration: none;
    color: #000;
}
.scrollable-filter{
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    scrollbar-color: #19a767 #dbdbdb;
    height: 71vh;
    padding: 2%;

}

.leftFilterBox{
    width: 25%;
    height: fit-content;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    top: 4.2rem;
    position: sticky;
}
.appliedFilter .applyBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.appliedFilter ul li{
    display: flex;
    align-items: center;
    font-size: .7rem;
    width: fit-content;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* border: 1px solid #29192B; */
    padding: 3px 8px;
    font-weight: 600;
    border-radius: .5rem;
    color: #29192B;
    cursor: pointer;
}

.appliedFilter ul li:hover{
    background-color: #29192b1c;
}

.appliedFilter ul li:hover span{
    /* text-decoration: line-through; */
    color: #29192B;
}
.appliedFilter ul li .fltrXmark{
    color: #29192B;
    text-align: center;
    font-size: .5rem;
    border: 1px solid #29192B;
    width: 0.8rem;
    height: 0.8rem;
    line-height: 0.8rem;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 0.5rem;
}
.appliedFilter ul li:hover .fltrXmark{
    color: #000000;
}

.salerent-switch{
    border-radius: 6px;
    width: 50%;
    display: flex;
}

.propertyrentsale p{
    margin: 0;
    font-size: 0.9rem;
    font-weight: 600;
}


.salerent-switch .propertyrentsale {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    
  }
  

  
  .salerent-switch  .propertyrentsale label {
    font-size: 10px;
    font-weight: bold;
    color: #333;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin: 0;
  }

  .salerent-switch  .propertyrentsale input {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  


/* .propertyrentsale{
    display: flex;
    align-items: center !important;
}
.propertyrentsale p{
    font-weight: 600;
}

.propertyrentsale input{
    display: block;
    margin: 0;
    padding: 0;
} */

.filter{
    margin: 1rem;
}

.filter .filterhead button{
    border: none;
    font-size: .9rem;
    color: rgb(43, 7, 248);
    font-weight: 600;
}
.searchingSection .firstBox .filter{
    margin: 1rem 0;
}



.searchingSection .filter .filterhead{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.9rem;
    padding:2px 5px;
    border-radius: 4px;
    margin-top: 1rem;
    color: #29192B;
}

.searchingSection .filter .filterhead h6{
    font-size: 1.2rem;
    margin: 0;
}

.searchingSection .firstBox .filter .inputBudget{
    width: 100%;
}
#proprtyTypeFilter button{
    border: none;
    border-bottom: 1px solid #ddd;
    background-color: transparent;
    font-size: .8rem;
    border-radius: 1rem;
    background-color: #a9a9a9;
}
.filter ul{
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    margin: 0;padding: 0;
}
.filter ul li{
    list-style: none;
    padding:3px 6px;
    margin: 5px;
    border-radius: 1rem;
    border: 1px solid #EBEBEB;
    font-size: .8rem;
}

.filter ul li i{
    margin-right: 2px;
}

.filter ul li:hover{
    background-color: #29192b1c !important;
    cursor: pointer;
}
.filter ul li a{
    text-decoration: none;
    color: rgb(71, 70, 70);
}
.filter ul li:hover{
    background-color: #ddd;
}
.activepro , .activestatus{
    background-color: orangered !important;
    color: #fff;
}

.rightDataBox{
    width: 73%;
    background-color: #EBEBEB;
    border-radius: 10px;
    overflow: hidden;
}

.rightDataBox .listingHead{
    padding: 1% 2%;
    color: #19a767;
}

.rightDataBox .listingHead a{
    color: #19a767;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    background-color: #fff;
    padding: 0.3rem 1rem;
    border-radius: 10px;

}


.rightDataBox .list-container{
    padding: 0 2%;
}