.echron-bar-item{
    padding: 20px 0 0;
}
.echron-bar-item ul {
    display: flex;
    gap: 75px;
    list-style: none;
}
.echron-bar-item ul li {
    color: #000;
}
.echron-bar-item ul li select {
    border: none;
    position: relative;
}
.echron-bar-item ul li .icon{
    position: relative;
}
.echron-bar-item ul li .icon i{
    content: "";
    position: absolute;
    bottom: 4px;
    margin-left: -20px;
}

.EchronGirl{
    margin-top: 40px;
    padding: 0;
}