.EchronTrending{
    padding: 80px 0;
    width: 100%;
    background-color: #fff;
}
.EchronTrending .ul {
    display: grid;
    height: 100%;
    gap: 10px;
}
.EchronTrending .li {
    position: relative;
    height: 200px;
    border-radius: 10px;
}
.EchronTrending .li:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 410px;
}
.EchronTrending .li:nth-child(2) {
    grid-column: 2;
    grid-row: 1 / 2;
}
.EchronTrending .li:nth-child(3) {
    grid-column: 3 / 5;
    grid-row: 1 / 2 ;
}
.EchronTrending .li:nth-child(4) {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
}
.EchronTrending .li:nth-child(5) {
    grid-column: 4 / 5;
    grid-row: 2 / 3;
}
.EchronTrending .li figure {
    display: block;
    background-color: #000;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 15px;
}
.EchronTrending .li:hover figure img {
    transform: scale(1.1);
}
.EchronTrending .li figure img {
    width: 100%;
    height: 100%;
    opacity: 0.4;
    object-fit: cover;
    transition: .5s;
    
}
.EchronTrending .li:nth-child(1){
    position: relative;
}
.EchronTrending .li .Matter {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: #fff;
    width: 173px;
}
.EchronTrending .li .Matter h2{
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 15px;
    font-family: Arial, Helvetica, sans-serif;
}
.EchronTrending .li .Matter h4{
    text-transform: capitalize;
    text-align: center;
    font-size: 1.2rem;
}




/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 1200px) and (max-width: 1599px) {

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 1600px){

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 992px) and (max-width: 1199px) {
   
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 768px) and (max-width: 991px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 767px) {
    
    .Echron-Samplework {
      width: 100%;
      padding: 0 0 0px;
   }
  .EchronTrending .ul {
    display: unset;
   }
  .EchronTrending .li {
    height: 150px;
    margin-bottom: 20px;
   }
  .EchronTrending .li:nth-child(1) {
  height: 150px;
  }
}