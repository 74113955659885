header {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
}
img {
    max-width: 100%;
}
.echron-inner-header .echron-header {
    background-color: #ffffff;
}

.echron-inner-header .echron-header ul li :hover{
    color: #19a767;
    border-color: #19a767;
}
.echron-header.fixed {
    position: fixed;
    transition: ease-in 0.1;
    transition-timing-function: ease-in 0.5;
    transition: all 0.3s ease-in-out;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    transition-property: all;
    top: 0;
    background: #19a767;
    z-index: 11;
    width: 100%;
    box-shadow: 0px 10px 7px rgba(0, 0, 0, 0.5);
}
/* .echron-header .logo .fixed {
    display: none;
} */
.echron-header .logo img.yes-fixed {
    display: none;
}

.echron-header.fixed .logo img.yes-fixed {
    display: block;
}

.echron-header .logo img.no-fixed {
    display: block;
}

.echron-header.fixed .logo img.no-fixed {
    display: none;
}

.echron-header {
    height: 60px;
    padding: 5px 0px;
}
.echron-header.fixed nav>ul>li.number a{
    color: #fff;
    border: 2px solid #fff;
}
.echron-header.fixed nav>ul>li{
    color: #fff;
}
.echron-header  nav>ul>li.number a {
  
    border-radius: 25px;
    padding: 10px 15px;
}

.echron-header  .number a {
    border: 2px solid #000;
}

.echron-header img{
    height: auto;
    width: 196px;
    padding: 10px 0;
}
.echron-header ul {
    color: aqua;
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    list-style: none;
}
.echron-header ul li a{
    text-decoration: none;
    color: #000;
    font-weight: 600;
}
.echron-header.fixed ul li a{
    color: #fff;
}
.echron-header ul li {
    color: #000000a3;
    text-transform: capitalize;
    padding: 9px 20px;
    cursor: pointer;
    margin: auto 0;
}

.echron-header ul li :hover{
    color: #ffffff;
    border-color: #ffffff;
}
/* .echron-header ul li :hover a{
    border-color: #fff59a;
} */


.echron-header ul li .icon i{
    font-size: 1.3rem ;
}

.header-button i{
    font-size: 2rem;
}


/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 1200px) and (max-width: 1599px) {

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 1600px){

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 992px) and (max-width: 1199px) {
   
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 768px) and (max-width: 991px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 767px) {
    
    .echron-header .mone{
        width: 34%;
    }
    .echron-header .header{
        width: 64%;
    }
    
    .echron-header nav>ul>li.number a{
        display: none;
    }
    .echron-header.fixed nav>ul>li.number a{
        display: none;
    }
    
}