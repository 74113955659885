footer{
    background-color: #f5f5f5;
    margin: 0;
    padding: 35px 0px 0px 0px
}
footer .main-footer{
    margin-bottom: 50px;
}
footer .content.pr{
    padding-right: 50px;
}
footer .content ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}
footer .content li {
    list-style: none;
    font-size: 13px;
}
footer .content a {
    color: #333;
}

footer .content p{
    text-align: justify;
    font-size: 14px;
}
footer .content .h2{
    font-size: 18px;
    color: #333;
}
footer .mone{
    padding: 20px 5px;
}
footer .mone .app {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 15px;
}
footer .mone .icon {
    height: 100%;
}
footer .mone .icon ul {
    display: flex;
    gap: 5px;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    flex-wrap: wrap;
}
footer .mone .icon ul li {
    width: 35px;
    height: 35px;
    background-color: #000;
    border-radius: 50%;
}
footer .mone .icon ul li.fb {
    background-color: #316FF6;
}
footer .mone .icon ul li.tr {
    background-color: #1DA1F2;
}
footer .mone .icon ul li.in {
    background-color: #0077b5;
}
footer .mone .icon ul li.yt {
    background-color: #CD201F;
}
footer .mone .icon ul li.ig {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}
footer .mone .icon ul li a {
    color: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
footer .mone .icon ul li i{
    font-size: 18px;
    margin: 0;
}
footer .footer {
    background-color: #29192b;
    margin-top: 25px;
}
footer .footer ul {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    list-style: none;
    margin: 0 85px;
}
footer .footer ul a {
    color: #ffffff;
    text-decoration: none;
}
footer .foot {
    padding-bottom: 10px;
}
footer .foot p{
    text-align: center;
    font-size: 10px;
    margin: 15px 0;
}
footer .feet {
    position: fixed;
    z-index: 99;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
}   
footer .feet {
    padding: 10px 0;
    background-color: #29192b;
    opacity: 0;
    display: none;
    transition: .5s;
}
footer .feet.active {
    opacity: 1;
}
footer .feet .form{
    display: flex;
    position: relative;
    justify-content: space-between;
  
}
footer .feet .form form{
    display: flex;
}
footer .feet .form form .child{
    width: 24%;
    margin-right: 10px;
}

footer .feet .form figure{
    position: absolute;
    bottom: 0;
    height: 100px;
    width: max-content;
}

footer .feet .form figure img {
    width: 100%;
    height: 100%;
    margin: 0 5x 0 5px;
}
footer .feet .form figure {
    margin: 0;
}
footer .feet .form input {
    padding: 0 35px;
    margin-right: 65px;
    height: 35px;
}

::placeholder{
    font-size: 0.9rem;
}

footer .feet .form select {
    height: 35px;
    padding: 0.1rem 2.25rem .375rem .75rem;
}
footer .feet .form .button {
    width: 100%;
    height: 35px;
    background-color: #19a767;
    color: #fff;
    border-radius: 10px;
    border: none;
    transition: .5s;
    font-size: 0.8rem;
}
footer .feet .form .button:hover {
    background-color: #fff;
    color: #19a767;
}






/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 1200px) and (max-width: 1599px) {

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 1600px){

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 992px) and (max-width: 1199px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 768px) and (max-width: 991px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 767px) {
    
    footer .footer ul {
        display: flex;
        padding: 20px;
        list-style: none;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        margin: 0 1px;
      }
      footer .footer ul li {
        margin-bottom: 5px;
        border: 1px solid gray;
        padding: 5px;
        margin: 5px 5px 0 0;
      }
    footer .feet {
        padding-bottom: 0;
        overflow: hidden;
    }
    
    footer .feet .container .row .col-5 {
        margin-bottom: 10px;
        width: 41%;
        margin-bottom: 10px;
        margin-right: 30px;
    }
    footer .feet .container .row .col-6 {
        margin-bottom: 5px;
        width: 50%;
        padding: 0 3px 0 3px;
    }
    footer .feet .container .row .col-8 {
        margin-bottom: 10px;
        width: 40%;
    }
    footer .feet .form .button {
        margin-bottom: 10px;
        width: 100%;
        
    }
    footer .feet .form {
        display: unset;
    }
    footer .feet .form form .child {
        width: 100%;
        margin-bottom: 10px;
    }
    footer .feet .form form {
        display: unset;
    }
    footer .feet .form input {
        padding: 0px 5px;
    }

    footer .content.pr{
        padding-right: 0px;
    }
}