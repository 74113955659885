/* .Echron-Add .promation-banner {
    width: 100%;
    height: 100%;
    position: relative;
}

.Echron-Add .promation-banner img {
    width: 100%;
    height: 100%;
}
.Echron-Add .promation-banner h3{
    position: absolute;
    top: 80px;
    left: 65px;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
}
.Echron-Add .promation-banner h3 span{
    font-weight: 500;
    font-size: 35px;
} */
.echron-intro {
	background: url("../img/call_bg.jpg") no-repeat;
	background-size: cover;
}
.echron-intro {
    padding: 40px 25px;
}
.echron-intro-text h3 {
    font-size: 24px;
    margin: 0;
    line-height: 50px;
    color: #fff;
}
.echron-intro .btn {
    background: #fff;
    color: #37404d;
    border-radius: 100px;
    box-shadow: 0 10px 30px 0 rgba(58, 87, 135, .15);
    padding: 15px 30px;
    transition: .5s;
    font-size: 0.8rem;
    font-weight: 700;
}
.echron-intro .btn:hover {
    background: #37404d;
    color: #fff;
}
.echron-btn-md {
    cursor: pointer;
    padding: 13px 30px 11px 30px;
    letter-spacing: 1.5px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 3px;
}






/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 1200px) and (max-width: 1599px) {

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 1600px){

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 992px) and (max-width: 1199px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 768px) and (max-width: 991px) {
   
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 767px) {
    .echron-intro .btn {
        padding: 7px 15px;
        margin-top: 10px;
    }
   
}