.Echron-Samplework{
    width: 100%;
    padding: 0 0 40px;
}
.Echron-Samplework .img img{
    width: 100%;
}
.Echron-Samplework .img {
    text-align: center;
    color: #999;
    font-weight: 600;
}

.echron-cards figure {
  width: 100%;
  height: 170px;
  overflow: hidden;
  border-radius: 15px ;
  overflow: hidden;
}


.echron-cards figure .carousel-control-prev,
.echron-cards figure .carousel-control-next {
  height: 170px;
  
}

  .echron-cards figure img {
  width: 100%;
  height: 170px;
  border-radius: 15px;
  transition: 1s;
}

.Echron-Samplework h2{
    text-align: center;
    text-transform: capitalize;
    font-size: 25px;
    font-weight: 600;
    color: #000;
}

.Echron-Samplework .sliderimg{
    height: 165px;
    width: 100%;
    overflow: hidden;
    text-align: center;
    margin-top: 20px;
}
.Echron-Samplework .sliderimg img{
    height: 100%;
    width: 100%;
    transition: 0.5s ease-in-out;
}
.Echron-Samplework .sliderimg .EchronButton{
    border: none;
    color: #19a767;
    background-color: #fff;
}
.Echron-Samplework .sliderimg img:hover{
    scale: 1.1;
}

.cardflex{
  display: flex;
  flex-direction: row;
}

/* content */
/* content */
/* content */
/* content */
/* content */
.Echron-Samplework .content{
  padding: 0 10px 10px 10px;
    color: #000;
  }
  .Echron-Samplework .content .name {
    display: flex;
    justify-content: space-between;
  }
  .Echron-Samplework .content .name .h3 {
    font-size: 15px;
    color: #555;
    /* width: 170px; */
  }
  .Echron-Samplework .content .loc p span{
    text-transform: capitalize;
    color: #000;
  }
  .Echron-Samplework .content .loc p span{
    text-align: left;
  }
  .Echron-Samplework .content .loc h6{
    text-transform: capitalize;
    color: #555;
  }
  .Echron-Samplework .content .name .tag {
    border-radius: 25px;
    font-weight: 700;
    text-transform: uppercase;
    background: #f1f1f1;
    padding: 0 0.5rem;
 
    /* height: 25px; */
    width: fit-content;
    font-size: 12px;
  }
  /* .echron-cards .content .name .tag:nth-child(2) {
    width: fit-content;
  } */
  .Echron-Samplework .content .name .tag i {
    content: "";
    color: green;
    font-size: 12px;
  }
  .Echron-Samplework .content .loc .h4{
    margin: 10px 0px;
    font-size: 14px;
  }
  .Echron-Samplework .content .sqft {
    border-bottom: 2px solid #f1f1f1;
    display: flex;
    justify-content: center;
    font-size: 14px;
    text-transform: uppercase;
  }
  .Echron-Samplework .content .sqft .left{
    box-sizing: border-box;
  }
  .Echron-Samplework .content .price{
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    align-items: center;
    font-size: 14px;
  }
  .Echron-Samplework .content .price h5{
    color: #000;
    margin: 0;
    
  }
  .Echron-Samplework .content .price .button a{
    padding: 5px 20px;
    background: #f1f1f1;
    border-radius: 25px;
    border: none;
    color:#19a767;
    transition: all .5s;
    text-decoration: none;
  }
  .Echron-Samplework .content .price .button a:hover{
    color:#fff;
    background: #19a767;
  }

  .view-morebutton a{
    text-decoration: none;
    border: 1px solid #19a767;
    background-color: #19a767;
    color: #ffffff;
    padding: 0.5rem 0.9rem;
    font-weight: 600;
    border-radius: 10px;
    font-size: 1rem;

  }


  .hiddencard.active:nth-child(1) {
    animation: myanim 1s linear;
    transition: 1s;
  }
  .hiddencard.active:nth-child(2) {
    animation: myanim 1.5s linear;
    transition: 1.5s;
    opacity: 100%;
  }
  .hiddencard.active:nth-child(3) {
    animation: myanim 2s linear;
    transition: 2s;
    opacity: 100%;
  }
  .hiddencard.active:nth-child(4) {
    animation: myanim 2.5s linear;
    transition: 2.5s;
    opacity: 100%;
  }

  .hiddencard.active:nth-child(5) {
    animation: myanim 1s linear;
    transition: 1s;
  }
  .hiddencard.active:nth-child(6) {
    animation: myanim 1.5s linear;
    transition: 1.5s;
    opacity: 100%;
  }
  .hiddencard.active:nth-child(7) {
    animation: myanim 2s linear;
    transition: 2s;
    opacity: 100%;
  }
  .hiddencard.active:nth-child(8) {
    animation: myanim 2.5s linear;
    transition: 2.5s;
    opacity: 100%;
  }
  .hiddencard.active:nth-child(9) {
    animation: myanim 1s linear;
    transition: 1s;
  }
  .hiddencard.active:nth-child(10) {
    animation: myanim 1.5s linear;
    transition: 1.5s;
    opacity: 100%;
  }
  .hiddencard.active:nth-child(11) {
    animation: myanim 2s linear;
    transition: 2s;
    opacity: 100%;
  }
  .hiddencard.active:nth-child(12) {
    animation: myanim 2.5s linear;
    transition: 2.5s;
    opacity: 100%;
  }
  .hiddencard.active:nth-child(13) {
    animation: myanim 1s linear;
    transition: 1s;
  }
  .hiddencard.active:nth-child(14) {
    animation: myanim 1.5s linear;
    transition: 1.5s;
    opacity: 100%;
  }
  .hiddencard.active:nth-child(15) {
    animation: myanim 2s linear;
    transition: 2s;
    opacity: 100%;
  }
  .hiddencard.active:nth-child(16) {
    animation: myanim 2.5s linear;
    transition: 2.5s;
    opacity: 100%;
  }
  .hiddencard.active:nth-child(17) {
    animation: myanim 1s linear;
    transition: 1s;
  }
  .hiddencard.active:nth-child(18) {
    animation: myanim 1.5s linear;
    transition: 1.5s;
    opacity: 100%;
  }
  .hiddencard.active:nth-child(19) {
    animation: myanim 2s linear;
    transition: 2s;
    opacity: 100%;
  }
  .hiddencard.active:nth-child(20) {
    animation: myanim 2.5s linear;
    transition: 2.5s;
    opacity: 100%;
  }

  @keyframes myanim {
    0% {
      opacity: 0%;
      transform: translateY(100px);
    }
  }











/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 1200px) and (max-width: 1599px) {

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 1600px){

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 992px) and (max-width: 1199px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 768px) and (max-width: 991px) {
   
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 767px) {
    
    .Echron-Samplework {
      width: 100%;
      padding: 0 0 0px;
   }
}