.Echron-Sliders{
    padding: 40px 0 40px;
    width: 100%;
    background-color: #ffffff;
}
.Echron-Sliders h2{
    text-align: center;
    text-transform: capitalize;
    font-size: 25px;
    font-weight: 600;
    padding: 10px 0;
}
.Echron-Sliders p{
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    color: #999;
}
.Echron-Sliders .SliderText{
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-align: center;
    margin-top: 20px;
}
.Echron-Sliders .SliderText img{
    height: 150px;
    transition: 0.5s ease-in-out;
    margin: auto;
}
.Echron-Sliders .SliderText .EchronButton{
        border: none;
        color: #19a767;
        background-color: #fff;
    }
.Echron-Sliders .SliderText img:hover{
    scale: 1.1;
}

/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 1200px) and (max-width: 1599px) {

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 1600px){

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 992px) and (max-width: 1199px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 768px) and (max-width: 991px) {
   
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 767px) {
    .Echron-Sliders .slick-slider button.slick-arrow {
        display: none !important;
    }
   
}