
.EchronHomebanner {
  background-color: #F5EEE6;
}
.index-banner .echron-main{
    width: 95%;
    height: auto;
    border-bottom-left-radius: 160px;
    border-bottom-right-radius: 160px;
    overflow: hidden;
    margin: auto;
    background-color: #495679;
    
}

.echron-main{
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: auto;
  background-color: #495679;
  
}


/* .EchronHomebanner .banner-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 420px;
  filter: brightness(90%) opacity(0.3);
} */

.EchronHomebanner .list{
  display: block;
}





.banner-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 600px;
}

.EchronHomebanner .echron-main{
    position: relative;
}

/* .index-banner .EchronHomebanner .echron-content {
  padding-bottom: 1rem;
} */

.EchronHomebanner .echron-content {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    text-align: center;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 60%, rgb(0, 0, 0));
    padding-bottom: 1rem;
}
.EchronHomebanner .echron-content .heading {
    margin-bottom: 25px;

}
.EchronHomebanner .echron-content .heading .h1 {
    font-size: 50px;
}
.EchronHomebanner .echron-content .heading .h2 {
    font-size: 15px;
}
/* .index-banner .EchronHomebanner .echron-content .custom-select{
    margin-bottom:8rem;
} */

.EchronHomebanner .echron-content .custom-select{
  /* padding: 1rem; */
  width: 45%;
  margin: auto;
  position: relative;
  /* background: var(--greyLight-1); */
  border-radius: 1rem;
  margin-bottom:220px;
  border-radius: 24px;
}

.EchronHomebanner .echron-content .custom-select select{
    color: #000;
    width: 300px;
    height: 40px;
    border: none;
    padding-left: 10px;
    border-radius: 12px;
    margin: 0 2px;
    border: none;
  }



  .EchronHomebanner .echron-content .custom-select .btn {
    width: 50px;
    border: none;
    /* background-color: #19a767; */
    font-size: 1.2rem;
    color: #fff;
    height: auto;
    border-radius: 18px;
    line-height: 19px;
    
}

.SearchFilterBox .microphone{
  /* width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  font-size: 1.4rem;
  display: block;
  padding: 0; */
  color: #495679;
}



.SearchFilterBox .search-front{
  color: #000;
  font-size: .8rem;
}

.speachContaner {
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translate(0%, -50%);
  height: 20px;
  z-index: 3;
  background-color: #fff;
  --dot-size: 4px;
  --max-block-size: calc(var(--dot-size) * 5);
  --dot-color: lch(80.94% 62.75 148.82);
  --dot-color-transition-1: lch(59.2% 85.33 318.2);
  --dot-color-transition-2: lch(95.29% 85.33 97.41);
  --delay: 0ms;
  /* display: none; */
  border-radius: 1px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(4.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.speechContainer.hidden {
  display: none !important;
}
.speachContaner.active{
  display: block;
  
}
.speachContaner > .microphone2.active{
  display: block;
}
.speachContaner h3{
  color: #000;
  margin-bottom: 1rem;
}
/* .speachStop{
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  font-size: 1.7rem;
} */

.mic-container{
  position: relative;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(var(--dot-size) / 2);
  block-size: var(--max-block-size);
  margin: auto;
}

.dot {
  inline-size: var(--dot-size);
  block-size: var(--dot-size);
  border-radius: calc(var(--dot-size) / 2);
  background: var(--dot-color);
  animation: y-grow 2s infinite ease-in-out;
  animation-delay: calc(var(--delay) * 1ms);
}

@keyframes y-grow {
  25% {
    block-size: var(--max-block-size);
    background-color: var(--dot-color-transition-1);
  }
  50% {
    block-size: var(--dot-size);
    background-color: var(--dot-color-transition-2);
  }
}



/* .EchronHomebanner .echron-content .form {
    display: flex;
    gap: 10px;
    position: relative;
    justify-content: center;
}
.EchronHomebanner .echron-content .form .input {
    width: 600px;
    height: 50px;
    border: none;
    padding-left: 10px;
}*/
/* .EchronHomebanner .echron-content .form .btn {
    width: 125px;
    border: none;
    background-color: #19a767;
    color: #fff;
    height: 50px;
    border-radius:0;
    transform: background-color .5s;
}  */
.EchronHomebanner .echron-content .form .btn:hover {
    background-color: #000;
}
.EchronHomebanner .echron-content .nbsp{
    margin-bottom: 30px;
}
.EchronHomebanner .echron-content .key {
    /* border: 2px solid #fff; */
    padding: 5px 25px;
    border-radius: 25px;
    text-align: center;
    display: inline-block;
    margin: 45px 0;
    background-color: #495679;
    border: 1px solid #fff;
}

.EchronHomebanner .echron-content .key a{
    text-decoration: none;
    color: #ffffff;
}

.EchronHomebanner .list ul {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 80%;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 15px;
}
.EchronHomebanner ul li {
    display: inline-block;
    border-left: 1px solid #fff;
    width: 20%;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    /* z-index: -2; */
}
.EchronHomebanner .list li a{
  text-decoration: none;
  color: #fff;
}
.EchronHomebanner ul li .span:first-child {
    font-weight: 600;
}
.EchronHomebanner ul li:first-child {
    border: none;
}
.EchronHomebanner .carousel-item {
    
    height: 450px !important;
  }
  .filterTopbar{
    width:fit-content;
    margin: auto;
    border-radius: 0.5rem;
    display: none;
  }
  .filterTopbar ul{
    display: flex;
    margin: 0;
    background-color: #E6A4B4;
    border-radius: 1rem;
    margin-bottom: 0.5rem;
    padding: 0.3rem ;
    border-radius: 0.8rem;
  }
  .filterTopbar ul li{
    width: fit-content;
    padding: 0.1rem 1rem 0.1rem 1rem;
    background: rgba(255, 255, 255, 0);
    border: none;
    margin-right: 10px;
    cursor: pointer;
    color: #ffffff;
  }

  .filterTopbar ul li:last-child{
    margin-right: 0;
  }

  .SearchFilterBox{
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 24px;
    position: relative;
    /* overflow: hidden; */
    background-color: #fff;
    padding-left: 1.5%;
  }

  .SearchFilterBox::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px; /* Adjust this to control the gap from the left edge */
    right: 20px; /* Adjust this to control the gap from the right edge */
    height: 1px; /* Thickness of the border */
    background-color: rgba(0, 0, 0, 0.158); /* Border color */
    
  }
  
  .SearchFilterBox input{
    width: 85%;
    display: block;
    border: none;
    outline: none;
    position: relative;
    border-radius: 0.5rem;
    padding-left: 1.5%;
    font-size: 0.9rem;
  }


  .fa-microphone{
    color: #000;
    width: 2rem;
    background-color: transparent;
    padding: 1.5%;
    cursor: pointer;
  }
  .microphone-icon.listening {
    color: red; 
}
  .SearchFilterBox input::placeholder{
    color: grey;
  }
  .SearchFilterBox button{
    width: 10%;
    border: none;
    outline: none;
    font-size: 1.1rem;
    color: #19a767;
    background-color: #ffffff;
    height: 2.5rem;
    padding-right: 10px; 
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  .SearchFilterBox button i{
    filter: drop-shadow(0 0 0.75rem #19a767);
}


  .SearchFilterBox:hover button{
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  }

  .srchCtr{
    background-color: red;
    color: white;
  }
  .searchCtr.active {
    background-color: #495679;
    color: #ffffff;
    /* margin-left: 0.3rem; */
    border-radius: 0.5rem;
    /* box-shadow: inset 0.2rem 0.2rem 0.5rem var(--greyLight-2), inset -0.2rem -0.2rem 0.5rem var(--white); */
    pointer-events: none;

  }
  .searchboardBox{
    background-color: #fff;
    width: 100%;
    /* padding: 2%; */
    max-height: 190px;
    padding-bottom: 5px;
    overflow: hidden;
    /* overflow-y: scroll; */
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow-x: hidden;
   
    scrollbar-width: 0;
    /* border-radius: .5rem; */
    color: #000;
    display: none;
    position: absolute;
    margin-top: 0.2rem;
    padding-top: 1.5rem;
  }

  .searchboardBox::-webkit-scrollbar {
    display: none;
}

  .searchboardBox.active{
    display: block;
    top: 49%;
    z-index: -1;
  }

  .searchboardBox.active   .SearchFilterBox{
    border: none;
  }

  .searchboardBox.dactive{
    display: none;
  }
  .searchboardBox .ullistboard{
    width: 100% !important;
    display: flex;
    align-items: center;
    padding: 0.2rem 1.5%;
    margin-bottom: 0.3rem;
    gap: 0.5rem;
  }
  .searchboardBox .ullistboard i{
    display: block;
    font-size: .8rem;
  }
  .searchboardBox .ullistboard p{
    text-transform: lowercase;
    color: #000;
    margin: 0;
    font-size: .9rem;
    
  }
  .searchboardBox .ullistboard:hover{
    background-color: #dadada;
 
  }
  .ullistboard.active {
    background-color: #dadada;
    
    
  }
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 1200px) and (max-width: 1599px) {

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 1600px){

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 992px) and (max-width: 1199px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 768px) and (max-width: 991px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 767px) {
    
    .EchronHomebanner .banner-image{
        height: 350px;

    }
    .EchronHomebanner .banner-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    /* .EchronHomebanner .echron-content {
        position: absolute;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #fff;
        text-align: center;
        width: 80%;
        top: 50%;
    } */
    .EchronHomebanner .echron-content .heading {
        margin-bottom: 10px;
        color: #000;
    }
    .EchronHomebanner .echron-content .heading .h1 {
        font-size: 22px;
        
    }
    .EchronHomebanner .echron-content .heading .h2 {
        font-size: 13px;
    }
    .EchronHomebanner .echron-content .form .input {
        height: 35px;
    }
    .EchronHomebanner .echron-content .form .btn {
        height: 35px;
    }
    .EchronHomebanner .echron-content .custom-select .btn p{
        display: none;
    }
    .EchronHomebanner .list ul{
        display: none;
    }
    .EchronHomebanner .echron-content .key {
        border: 2px solid #000;
        padding: 5px 15px;
        color: #000;
        font-size: 17px;
        font-weight: 600;
    }

    .EchronHomebanner .echron-content .custom-select select {
        width: 100px;
        height: 25px;
        
    }

    .EchronHomebanner .echron-content .custom-select{
        padding: 0.5rem;
    }
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 567px) {
    
}