/* .EchronPopular {
    padding: 40px 0;
}

.EchronPopular h2 {
    text-align: center;
    text-transform: capitalize;
    font-size: 25px;
    font-weight: 600;
    padding: 10px 0;
}

.EchronPopular .Card {
    height: 200px;
    position: relative;
    overflow: hidden;
    margin-bottom: 25px;
}
.EchronPopular .Card:before {
    content: '';
    position: absolute;
    top: 0;
    right: -30px;
    background: #7c4f4a;
    width: 60%;
    height: 100%;
    z-index: 1;
    transform: skew(15deg);
    opacity: 0.9;
    border-left: 3px solid #fff;
}
.EchronPopular .cards:nth-child(2n) .Card:before {
    background: #e03846;
}
.EchronPopular .cards:nth-child(3n) .Card:before {
    background: #a36735;
}
.EchronPopular .cards:nth-child(4n) .Card:before {
    background: #a7938c;
    right: auto;
    left: -30px;
    border-left: none;
    border-right: 3px solid #fff;
}
.EchronPopular .cards:nth-child(5n) .Card:before {
    background: #f7a02d;
    right: auto;
    left: -30px;
    border-left: none;
    border-right: 3px solid #fff;
}
.EchronPopular .cards:nth-child(6n) .Card:before {
    background: #717173;
    right: auto;
    left: -30px;
    border-left: none;
    border-right: 3px solid #fff;
}
.EchronPopular .Card img {
    width: 100%;
    transition: 0.5s ease-in-out;
    padding-bottom: 25px;
}

.EchronPopular .Card:hover img {
    scale: 1.1;
}
.EchronPopular .Card h3 {
    padding: 10px;
    text-align: center;
    width: 40%;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
    z-index: 1;
}
.EchronPopular .cards:nth-child(4n) .Card h3,
.EchronPopular .cards:nth-child(5n) .Card h3,
.EchronPopular .cards:nth-child(6n) .Card h3 {
    right: auto;
    left: 25px;
} */

















.Echron-Category{
    padding: 40px 0;
    width: 100%;
    background-color: #fff;
    padding-bottom: 8rem;
}
.Echron-Category h2{
    text-align: center;
    text-transform: capitalize;
    font-size: 25px;
    font-weight: 600;
    padding: 10px 0 0;
}
.Echron-Category p{
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    color: #555;
}
.Echron-Category .sliderimg{
    height: 280px;
    position: relative;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin-bottom: 24px;
    border-radius: 20px;
}
.Echron-Category .sliderimg h4{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: 500;
    font-size: 1.2rem;
    width: 100%;
    z-index: 1;
    text-transform: capitalize;
    text-align: center;
   
}
.Echron-Category .sliderimg img {
    height: 280px;
    width: 100%;
    transition: 0.5s ease-in-out;
    margin-bottom: 22px;
    border-radius: 20px;
    object-fit: cover;
}
.Echron-Category .sliderimg figure{
    display: block;
    margin: 0;
    height: 100%;
    overflow: hidden;
    width: 100%;
    border-radius: 20px;
    
    
}
.Echron-Category .sliderimg figure:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    z-index: 1;
    background-color: #00000075;
    height: 280px;
    width: 100%;
    border-radius: 20px;
    transition: 1s;
}
.Echron-Category .sliderimg:hover figure img{
    scale: 1.1;
}

.Echron-Category .sliderimg:hover figure:before{
    background-color: #0000003f;
    transition: 1s;
   
}







/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 1200px) and (max-width: 1599px) {

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 1600px){

}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 992px) and (max-width: 1199px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (min-width: 768px) and (max-width: 991px) {
    
}
/* *************************Responsive****************************** */
/* *************************Responsive****************************** */
@media (max-width: 767px) {
    
    .Echron-Category {
        padding: 0px 0;
    }
}